import { CompletedOrder } from 'r2d2';
import { IS_PRODUCTION } from 'utils/constants';
import { captureException } from 'utils/sentry';

/* eslint-disable @typescript-eslint/no-explicit-any */
export const fetchAPI = async (
  query: string,
  {
    variables,
    headers,
    retries = 0,
    debug = false,
  }: {
    variables?: Record<string, unknown>;
    headers?: Record<string, string>;
    retries?: number;
    debug?: boolean;
  } = {},
): Promise<any> => {
  try {
    const res = await fetch(`${process.env.NEXT_PUBLIC_TARS_URL}/graphql`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        shop: process.env.NEXT_PUBLIC_SHOP_SLUG || '',
        shopVersion: process.env.NEXT_PUBLIC_VERSION || '',
        ...(headers || {}),
        // cache: 'maxAge=60',
      },
      body: JSON.stringify({
        query,
        variables,
      }),
    });
    if (process.browser) {
      console.log('@Fetch', {
        status: res.status,
        statusText: res.statusText,
      });
    }

    if (debug && !IS_PRODUCTION) {
      console.log('@@ fetch', `${process.env.NEXT_PUBLIC_TARS_URL}/graphql`);
      console.log('@@ headers', {
        'Content-Type': 'application/json',
        shop: process.env.NEXT_PUBLIC_SHOP_SLUG,
        shopVersion: process.env.NEXT_PUBLIC_VERSION,
        ...(headers || {}),
      });
      console.log('@@ query', query.replace(/\n/g, '\n'));
      console.log('@@ variables', variables);
    }

    const json = await res.json();

    if ((res.status >= 400 && res.status < 500) || json.errors) {
      console.error({
        errors: json.errors,
        query: query.substr(0, 100),
        variables,
        headers,
      });

      console.log('ERROR ', {
        status: res.status,
        errors: json?.errors,
      });

      captureException('failed to fetch tars api', {
        query,
        variables,
        headers,
        status: res.status,
        ...(json || {}),
        ...(json?.errors || {}),
        ...(json?.data || {}),
      });
    }
    return json.data;
  } catch (error) {
    if (retries === 5) {
      captureException(error, { query, variables, headers });
      return {};
    }
    console.log('@ Fetch retry', retries);
    return await fetchAPI(query, { variables, headers, retries: retries + 1 });
  }
};

export const pushlishToQueueThroughTars = async (order: CompletedOrder): Promise<boolean> => {
  try {
    console.log('@@ pushlishToQueueThroughTars', `${process.env.NEXT_PUBLIC_TARS_URL}/queue/order`, {
      token: order.token,
      orderId: order._id,
      organizationId: order.organizationId,
      locale: order.locale,
    });

    const res = await fetch(`${process.env.NEXT_PUBLIC_TARS_URL}/queue/order`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Basic YmVhdGRvd246Um13MkJobUNBVzRwZTZSUlZjRk4=`,
      },
      body: JSON.stringify({
        token: order.token,
        orderId: order._id,
        organizationId: order.organizationId,
        locale: order.locale,
      }),
    });

    const json = await res.json();
    return json.success;
  } catch (error) {
    captureException(error, { order });
    return false;
  }
};
