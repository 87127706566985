import styled from 'styled-components';

const PreviewModeButton: React.FC = () => {
  return (
    <StyledPreviewModeButton
      type="button"
      className="cart-button"
      onClick={() => {
        location.href = `/api/revoke-preview`;
      }}
    >
      <div>Preview Mode</div>
      <p>Klik for at disable</p>
    </StyledPreviewModeButton>
  );
};
const StyledPreviewModeButton = styled.button`
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1rem 2rem;

  border-radius: 1rem;
  background: var(--primary-background);
  color: var(--primary-foreground);

  position: fixed;
  left: 1rem;
  bottom: 1rem;

  div {
    font-size: 1.2rem;
    font-weight: 600;
  }
  p {
    font-size: 0.9rem;
  }
`;

export default PreviewModeButton;
