/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-danger */
import React, { useContext, useState, useEffect } from 'react';
// import { confirmAlert } from 'react-confirm-alert';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import styled from 'styled-components';
import { ContextProps, GlobalContext } from '../../context/global-context';
import { getProductBuyData } from '../../data/product';
import Viabill from './viabill';
import Countdown from './countdown';
import Button from '../formitems/button';
import { logBDEvent } from '../../utils/tracking';
import { useOrderStore } from '../../data/orders';
import { toast } from 'react-toastify';
import { fbTrack } from '../../utils/fbpixel';
import Loader from 'components/core/load-spinner';
import { AiOutlineShopping } from 'react-icons/ai';
import ProductNewsletter from 'components/core/product-newsletter';
import Input from 'components/formitems/input';
import { BuySectionProduct, CustomTextVariant, FullProductForPage, Locales } from 'r2d2';
import ReservationModal from './reservation-modal';
import Script from 'next/script';

type BuySectionProps = {
  product: FullProductForPage;
};

const BuySection: React.FC<BuySectionProps> = ({ product }) => {
  const { dictionary, setPopupState, shop } = useContext<ContextProps>(GlobalContext);
  const router = useRouter();
  const [loading, setLoading] = useState<boolean>(false);
  const [reservationIdAndSku, setReservationIdAndSku] = useState<{
    reservationId: string;
    exactSku: string;
  } | null>(null);
  const [customTextVariantValue, setCustomTextVariantValue] = useState<string>('');
  const [customTextVariantError, setCustomTextVariantError] = useState<string>('');
  const [customTextVariant2Value, setCustomTextVariant2Value] = useState<string>('');
  const [customTextVariant2Error, setCustomTextVariant2Error] = useState<string>('');
  const [loadingBuyButton, setLoadingBuyButton] = useState<boolean>(false);
  const [hasError, setHasError] = useState<string | null>(null);
  const currency = useOrderStore((state) => state?.order?.basket?.currency);

  const defaultSelectedVariant = (product.variants || []).reduce((prev, cur) => {
    if (cur.type === 'main' && cur.selected) {
      return { ...prev, [cur._id]: cur.selected };
    }
    return prev;
  }, {});
  const [selectedVariants, setSelectedVariants] = useState<Record<string, string>>(defaultSelectedVariant);
  const [productData, setProductData] = useState<BuySectionProduct>(product);
  const { asPath, locale } = router;
  const addToBasket = useOrderStore((state) => state.addToBasket);
  const baseUrl = `https://${shop!.domain}`;
  const variantSku = router.query?.variantSku as string;

  useEffect(() => {
    const getData = async (): Promise<void> => {
      setLoading(true);
      const data = await getProductBuyData(product.slug, asPath, locale! as Locales, selectedVariants || {});
      if (data?.variants) {
        const mainVariant = data.variants.find((variant) => variant.type === 'main');
        if (mainVariant && selectedVariants?.[mainVariant._id] !== mainVariant.selected) {
          setSelectedVariants({
            ...(selectedVariants || {}),
            [mainVariant._id]: mainVariant.selected,
          });
        }
      }
      if (data) {
        setProductData(data);
      }
      setLoading(false);
    };
    getData();
  }, [
    currency || shop?.defaultCurrency || 'DKK',
    product.slug,
    // asPath,
    JSON.stringify(selectedVariants),
    locale,
  ]);

  const {
    _id,
    price,
    inStock,
    millisecondsLeftToPreorder,
    confirmMessage,
    shippingStartDate,
    beforePriceFormatted,
    priceFormatted,
    variants,
    name,
    preorder,
    customTextVariant,
    customTextVariant2,
  } = productData;

  const validateCustomTextVariant = (customVariant: CustomTextVariant, value: string): string => {
    // "active": true,
    // "label": "Skriv dit navn",
    // "minLength": 1,
    // "maxLength": 10,
    // "casing": "normal",
    // "allowNumbers": false,
    // "allowLetters": true,
    // "allowSpecialCharacters": "æØ@#$",
    // "value": ""

    if (!customVariant.active) {
      // Check active
      return '';
    } else if (!value) {
      // Required
      return dictionary?.customTextVariantErrorRequired;
    } else if (value.length < customVariant.minLength) {
      // Min length
      return dictionary?.customTextVariantErrorMinLength.replace('{{minLength}}', customVariant.minLength.toString());
    } else if (value.length > customVariant.maxLength) {
      // Max length
      return dictionary?.customTextVariantErrorMaxLength.replace('{{maxLength}}', customVariant.maxLength.toString());
    } else if (!customVariant.allowLetters && /[a-zA-Z]/.test(value)) {
      // Letters
      return dictionary?.customTextVariantErrorLetters;
    } else if (!customVariant.allowNumbers && /[0-9]/.test(value)) {
      // Numbers
      return dictionary?.customTextVariantErrorNumbers;
    } else if (/[^A-Za-z0-9\s_]/.test(value)) {
      // Special characters

      const wrongSpecialCharacters: string[] = (value.trim().match(/[^A-Za-z0-9\s_]/g) || [])
        .map((char) => {
          if (!customVariant.allowSpecialCharacters.toLowerCase().includes(char.toLowerCase())) {
            return char;
          }
          return '';
        })
        .filter(Boolean)
        // Uniqueify
        .filter((char, index, array) => array.indexOf(char) === index) as string[];

      if (wrongSpecialCharacters.length) {
        return dictionary?.customTextVariantErrorSpecialChars.replace(
          '{{character}}',
          wrongSpecialCharacters.join(' '),
        );
      } else {
        return '';
      }
    } else {
      return '';
    }
  };

  const validateCustomText = (value: string): boolean => {
    const errorText = validateCustomTextVariant(customTextVariant, value);
    setCustomTextVariantError(errorText);
    return !errorText;
  };

  const validateCustomText2 = (value: string): boolean => {
    const errorText = validateCustomTextVariant(customTextVariant2, value);
    setCustomTextVariant2Error(errorText);
    return !errorText;
  };

  const changeCustomText = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    const value = e.target.value;
    if (customTextVariantError) {
      validateCustomText(value);
    }

    if (customTextVariant.casing === 'uppercase') {
      setCustomTextVariantValue(value.toUpperCase());
    } else if (customTextVariant.casing === 'lowercase') {
      setCustomTextVariantValue(value.toLowerCase());
    } else {
      setCustomTextVariantValue(value);
    }
  };
  const changeCustomText2 = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    const value = e.target.value;
    if (customTextVariant2Error) {
      validateCustomText2(value);
    }

    if (customTextVariant2.casing === 'uppercase') {
      setCustomTextVariant2Value(value.toUpperCase());
    } else if (customTextVariant2.casing === 'lowercase') {
      setCustomTextVariant2Value(value.toLowerCase());
    } else {
      setCustomTextVariant2Value(value);
    }
  };

  const updateCustomText = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    const value = e.target.value;
    validateCustomText(value);
  };
  const updateCustomText2 = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    const value = e.target.value;
    validateCustomText2(value);
  };

  const onBuyClick = async (): Promise<void> => {
    if (!validateCustomText(customTextVariantValue)) {
      return;
    }
    if (!validateCustomText2(customTextVariant2Value)) {
      return;
    }
    setHasError(null);
    setLoadingBuyButton(true);
    const res = await addToBasket(
      {
        amount: 1,
        productId: _id,
        selected: selectedVariants,
        customTextVariantValue: customTextVariantValue,
        customTextVariant2Value: customTextVariant2Value,
      },
      { locale: router.locale!, path: router.asPath },
    );
    if (res.success) {
      const { exactSku, type } = res.data; // eslint-disable-line
      fbTrack('AddToCart', {
        content_ids: exactSku as string,
        contents: [
          {
            id: exactSku,
            quantity: 1,
            item_price: Number(price.toFixed(2)),
          },
        ],
        content_type: 'product',
        content_name: name,
        currency,
        value: Number((price * 1).toFixed(2)),
      });
      logBDEvent('add-to-cart', exactSku as string, {
        name,
        amount: 1,
        price,
        currency,
      });
      if (type === 'REGULAR') {
        setPopupState('checkout');
      }
      if (type === 'PRIORITY' && res.data.reservationId) {
        console.log('res.data', res.data);
        setReservationIdAndSku({
          reservationId: res.data.reservationId,
          exactSku: res.data.exactSku,
        });
        setPopupState('product-reservation');
      }
      if (type === 'PRIORITY' && !res.data.reservationId) {
        // Der er bare tilføjet en vi har reservation på
        setPopupState('checkout');
      }
    } else {
      const { message, ...data } = res.data;

      toast(message as string, { type: 'error' });

      if ((data as { focus?: string })?.focus) {
        setHasError((data as Record<string, unknown>)?.focus as string);
      }
      if ((data as { customTextError: string })?.customTextError) {
        setCustomTextVariantError((data as { customTextError: string }).customTextError as string);
      }
      if ((data as { customText2Error: string })?.customText2Error) {
        setCustomTextVariant2Error((data as { customText2Error: string }).customText2Error as string);
      }
    }
    setLoadingBuyButton(false);
  };

  // PreOrder / InStock / OutOfStock / PreSale / SoldOut
  let availability = 'https://schema.org/InStock';
  if (preorder) {
    availability = 'https://schema.org/PreOrder';
  }
  if (!inStock) {
    availability = 'https://schema.org/OutOfStock';
  }

  return (
    <StyledBuySection className={`buy-section ${loading ? 'loading' : ''}`}>
      {/* Reserved product modal */}
      {reservationIdAndSku && (
        <ReservationModal
          reservationId={reservationIdAndSku.reservationId}
          exactSku={reservationIdAndSku.exactSku}
          onClose={() => setReservationIdAndSku(null)}
        />
      )}
      {beforePriceFormatted && <div className="before h3 light skel skal-s">{beforePriceFormatted}</div>}
      <div className="price h2 light">{priceFormatted}</div>
      {variants && variants.length > 0 && (
        <>
          <div className="variants">
            {variants.map((variant) => (
              <div
                className={classNames(
                  'variants-block',
                  { 'has-error': hasError === variant._id },
                  {
                    hide:
                      variant.type === 'main' &&
                      variant.attributes.length === 1 &&
                      (!selectedVariants || selectedVariants?.[variant._id]),
                  },
                )}
                key={variant._id}
              >
                <div className="label">{variant.name}</div>
                <div className="attributes-block">
                  {variant.attributes.map((attr) => (
                    <button
                      type="button"
                      key={attr.sku}
                      className={`attribute ${attr.selected ? 'selected' : ''} ${
                        attr.inStock ? 'in-stock' : 'not-in-stock'
                      } ${attr.color ? 'has-color' : 'has-text'}`}
                      style={{ backgroundColor: attr.color }}
                      onClick={() => {
                        if (variant.type === 'main') {
                          const pathArr = asPath.split('/');
                          pathArr[pathArr.length - 1] = attr.sku.toLowerCase();
                          const url = pathArr.join('/');
                          if (url !== asPath) {
                            setSelectedVariants({
                              [variant._id]: attr.sku,
                            });
                            router.push(url);
                          }
                        } else {
                          if (hasError === variant._id) {
                            setHasError(null);
                          }
                          // Vælg kun hvis den ikke allerede er valgt og den er på lager
                          if (attr.inStock && selectedVariants[variant._id] !== attr.sku) {
                            setSelectedVariants({
                              ...selectedVariants,
                              [variant._id]: attr.sku,
                            });
                          }
                        }
                      }}
                    >
                      {!attr.color && attr.name}
                    </button>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </>
      )}
      {customTextVariant.active && (
        <div
          className={classNames('custom-text-variant', {
            'has-error': !!customTextVariantError || hasError === 'customText',
          })}
        >
          <label htmlFor="custom-text-variant" className="label">
            {customTextVariant.label}
          </label>
          <Input
            onChange={changeCustomText}
            onBlur={updateCustomText}
            name="custom-text-variant"
            value={customTextVariantValue}
            placeholder={dictionary?.customTextVariantPlaceholder}
            errorMessage={customTextVariantError}
          />
        </div>
      )}

      {customTextVariant2.active && (
        <div
          className={classNames('custom-text-variant', {
            'has-error': !!customTextVariant2Error || hasError === 'customText',
          })}
        >
          <label htmlFor="custom-text-variant" className="label">
            {customTextVariant2.label}
          </label>
          <Input
            onChange={changeCustomText2}
            onBlur={updateCustomText2}
            name="custom-text-variant"
            value={customTextVariant2Value}
            placeholder={dictionary?.customTextVariantPlaceholder}
            errorMessage={customTextVariant2Error}
          />
        </div>
      )}

      {!inStock ? (
        <div>
          <hr />
          <ProductNewsletter productId={_id} />
        </div>
      ) : (
        <>
          <div className="buy">
            <div>
              <Button
                className="buy-button skel"
                size="large"
                disabled={loading || loadingBuyButton}
                onClick={onBuyClick}
                loading={loadingBuyButton}
                icon={AiOutlineShopping}
                alignIcon="left"
                confirmText={confirmMessage}
              >
                {preorder ? dictionary?.addPeorderToBasket : dictionary?.addToBasket} - {priceFormatted}
              </Button>
            </div>
          </div>

          {millisecondsLeftToPreorder && (
            <div className="preorder-info">
              <div className="days-left">
                <span className="message">{dictionary.leftToPurchase}</span>
                <Countdown millisecondsLeft={millisecondsLeftToPreorder} />
              </div>
              {shippingStartDate && (
                <div className="shipping-start">
                  {dictionary?.shippingStarts} {shippingStartDate}
                </div>
              )}
            </div>
          )}
        </>
      )}
      {inStock && currency === 'DKK' && process.env.NEXT_PUBLIC_SHOP_SLUG !== 'finite' && (
        <Viabill view="product" price={price} />
      )}

      {shop?.tracking.wishcloudButtonKey && (
        <div className="wishcloud">
          <Script
            src="https://storage.googleapis.com/gowish-button-prod/js/gowish-iframe.js"
            data-use-btn="true"
            type="application/javascript"
            id="gowish-iframescript"
            defer
          ></Script>
          <link rel="stylesheet" href="https://storage.googleapis.com/gowish-button-prod/css/gowish-iframe.css"></link>
          <button id="gowishWhiteButton" className="gowish-btn" type="button" data-region="denmark">
            Tilføj til Ønskeskyen
          </button>
        </div>
      )}

      {loading && <Loader />}
      <hr />

      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            '@context': 'https://schema.org/',
            '@type': 'Product',
            name: product.metaTitle || product.name,
            image: product.images.map((img) => `http://beatdown.imgix.net${img.src}`),
            description: product.metaDescription,
            brand: 'Beatdown Merchandise',
            sku: `${product.sku}${variantSku ? `_${variantSku.toUpperCase()}` : ''}`,
            offers: {
              '@type': 'Offer',
              url: `${baseUrl}/p/${product.slug}${variantSku ? `/${variantSku}` : ''}`,
              priceCurrency: shop!.defaultCurrency || currency || 'DKK',
              price: price.toFixed(2),
              availability,
              itemCondition: 'https://schema.org/NewCondition',
            },
          }),
        }}
      />
    </StyledBuySection>
  );
};
const StyledBuySection = styled.div`
  padding: 2rem 0 0 0;
  position: relative;

  .before {
    color: var(--base-dimmed-foreground);
    text-decoration: line-through;
    margin: var(--space-between-lines-margin);
    white-space: nowrap;
    opacity: 0.5;
  }

  .h1.price {
    margin: var(--space-between-lines-margin);
    white-space: nowrap;
    margin: 0 0 0.5rem 0;
  }

  .preorder-info {
    margin: 1rem 0;

    .shipping-start {
      font-size: 0.8rem;
      margin: 0.5rem 0;
      color: var(--secondary-background);
    }
  }
  .custom-text-variant {
    margin: 2rem 0;

    &.has-error {
      .label {
        color: var(--error-foreground);
      }
    }
    .label {
      display: block;
      font-size: 0.9rem;
      font-weight: 600;
      -webkit-letter-spacing: -0.5px;
      -moz-letter-spacing: -0.5px;
      -ms-letter-spacing: -0.5px;
      letter-spacing: -0.5px;
      margin: 1rem 0 1rem 0;
      line-height: 1rem;
      text-transform: uppercase;
    }
  }

  .variants {
    margin: 2rem 0;

    .variants-block {
      &.hide {
        display: none;
      }

      &.has-error {
        border: 1px solid var(--error-foreground);
        border-radius: 4px;
        padding: 0 1rem;
        margin-left: -1rem;
        margin-right: -1rem;

        .label {
          color: var(--error-foreground);
        }
      }
    }

    .label {
      font-size: 0.9rem;
      font-weight: 600;
      letter-spacing: -0.5px;
      margin: 1rem 0 1rem 0;
      line-height: 1rem;
      /* color: var(--primary-background); */
      text-transform: uppercase;
    }
    .attributes-block {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
      margin: 0 0 2rem 0;

      .attribute {
        min-height: 2rem;
        min-width: 2rem;
        border: 1px solid var(--grey);
        margin: 0 0.5rem 0.5rem 0;
        font-size: 0.8rem;
        font-weight: bold;

        &.has-text {
          padding: 4px 12px;
          border-radius: 100px;

          &.selected {
            background: var(--primary-background);
            border-color: var(--primary-background);
            color: var(--primary-foreground);
          }
        }

        &.not-in-stock {
          position: relative;
          opacity: 0.4;
          cursor: not-allowed;
          &:before {
            position: absolute;
            content: '';
            left: 50%;
            top: 0;
            bottom: 0;
            border-left: 1px solid;
            transform: rotate(45deg);
          }

          &.has-color {
            opacity: 0.3;
          }
        }

        &.has-color {
          border-radius: 50%;

          &.selected {
            box-shadow: 0 0 0 4px var(--primary-background);
            border-color: var(--primary-background);
          }
        }
      }
    }
  }

  .wishcloud {
    margin: 1rem 0;
  }
  .custom-text-variant {
    max-width: 360px;
  }

  .buy-button {
    margin: 1.5rem 0;
  }

  > .load-wrapper {
    transform: scale(0.5);
    position: absolute;
    bottom: 0.5rem;
    right: 0.5rem;
  }
`;
export default BuySection;
