import { fetchAPI } from './fetch-api-data';
import { getToken } from 'utils/helpers';
import { ParsedUrlQuery } from 'querystring';
import { ApiResponse, GetFullPageSuccessData, Locales } from 'r2d2';

export const newsletterSignup = async ({
  email,
  path,
  locale,
  mode,
  productId,
  tags,
}: {
  email: string;
  path: string;
  locale: string;
  mode: string;
  productId?: string;
  tags?: string[];
}): Promise<boolean> => {
  if (!process.browser) {
    throw new Error('addToBasket called from server');
  }

  const mutation = `
    mutation($email: String, $mode: String, $productId: String, $tags: [String]) { 
      newsletterSignup(email: $email, mode: $mode, productId: $productId, tags: $tags) 
    }
  `;
  const mutationData = await fetchAPI(mutation, {
    variables: { email, mode, productId, tags },
    headers: { locale, path, token: getToken() },
  });
  return mutationData.newsletterSignup;
};

export const add404Page = async ({
  path,
  query,
  locale,
}: {
  path: string;
  query: ParsedUrlQuery;
  locale: string;
}): Promise<string> => {
  if (!process.browser) {
    throw new Error('addToBasket called from server');
  }
  const mutation = `
    mutation($query: JSON) { 
      add404Page(query: $query) 
    }
  `;
  const mutationData = await fetchAPI(mutation, {
    variables: { query },
    headers: { locale, path, token: getToken() },
  });
  return mutationData.add404Page;
};

export const getUpdatedPage = async ({
  path,
  locale,
  passwordHash,
}: {
  path: string;
  locale: Locales;
  passwordHash?: string;
}): Promise<ApiResponse<GetFullPageSuccessData, null>> => {
  if (typeof window === 'undefined') {
    throw new Error('getCheckoutData called from server');
  }

  const updatedPage = await fetch('/api/content/get-page', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      token: getToken(),
      locale,
      path,
      passwordHash,
    }),
  });
  const data = (await updatedPage.json()) as ApiResponse<GetFullPageSuccessData, null>;

  return data;
};
