import styled from 'styled-components';
import Link from 'next/link';
import { FiChevronRight, FiHome } from 'react-icons/fi';
import React from 'react';
import { mqFrom, mqUntil } from 'styles/styles';
import { BreadcrumbItem } from 'r2d2';

export type BreadcrumbProps = {
  breadcrumb: BreadcrumbItem[];
  hide?: boolean;
};

export const Breadcrumb: React.FC<BreadcrumbProps> = ({ breadcrumb, hide = false }) =>
  breadcrumb && breadcrumb.length > 1 ? (
    <BreadcrumbStyled aria-label="Breadcrumbs" className={`breadcrumb ${hide ? 'hide' : ''}`}>
      <div className="inner">
        <ol itemScope itemType="http://schema.org/BreadcrumbList">
          {breadcrumb.map((item, index) => (
            <React.Fragment key={item._id}>
              {index > 0 && (
                <li aria-hidden="true" className="seperator" key={`sep-${item._id}`}>
                  <FiChevronRight />
                </li>
              )}
              {item.name === 'Home' && item.href === '/' ? (
                <li key={item._id}>
                  <Link prefetch={false} href={item.href} className={`page-${item.type}`}>
                    <span aria-label="Home">
                      <FiHome size={20} className="home" />
                    </span>
                  </Link>
                </li>
              ) : (
                <li
                  key={`listitem-${item._id}`}
                  itemProp="itemListElement"
                  itemScope
                  itemType="http://schema.org/ListItem"
                >
                  <Link
                    prefetch={false}
                    href={item.href}
                    className={`page-${item.type}`}
                    itemType="http://schema.org/Thing"
                    itemProp="item"
                  >
                    <span itemProp="name">{item.name}</span>
                  </Link>
                  <meta itemProp="position" content={index.toString()} />
                </li>
              )}
            </React.Fragment>
          ))}
        </ol>
      </div>
    </BreadcrumbStyled>
  ) : null;

const BreadcrumbStyled = styled.nav`
  /* background: var(--ultra-light-background); */
  padding: 1rem var(--side-padding);
  margin: 1rem 0 1rem 0;
  max-width: 100vw;

  &.hide {
    display: none;
  }

  .inner {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    ${mqUntil.desktop} {
      overflow-x: scroll;
    }
  }

  .home {
    transform: scale(0.8);
  }

  ol {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    align-items: center;
    /* flex-wrap: wrap; */

    li {
      margin: 0 0 0.3rem 0;
      min-height: 20px;

      &.seperator {
        padding: 0 0.5rem;
        color: var(--base-foreground);
        position: relative;
        top: 0.05rem;
      }

      a {
        text-decoration: none;
        color: var(--base-dimmed-foreground);
      }
      span {
        white-space: nowrap;
        font-size: 0.8rem;

        ${mqFrom.desktop} {
          font-size: 1rem;
        }
      }
    }
  }
`;

export default Breadcrumb;
